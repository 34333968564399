import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useRedux } from "../hooks/useRedux";
import { onChangeTextTranslateInput, onHandleStatusExtension, onHandleStatusExtensionTranslate,
   onSendMessageByExtension, onSendMessageByExtensionFail, onTranslationMessageId } from "../redux/chats/actions";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { onUpdateListMessageTranslate } from "../api/chats";

const { confirm } = Modal;

const EXTENSION_VERSION = "2.0.5";
const TRANSLATE_EXTENSION_VERSION = "1.1.6";

const LINK_EXTENSION = "https://drive.google.com/drive/folders/1Viz5tCTV07_QTASDOCETLCzKZDLpGIe4"


const AppExtension = () => {
    const { dispatch } = useRedux();

    const checkExtensions = (user: any)  => {
        window.postMessage({
          fb: {
            type: 'INIT',
            user: user
          }
        }, '*');
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (localStorage.getItem('authUser') && token) {
            const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');
            const user = { 
              userName: authUser?.name,
              userId: authUser?.id,
              accessToken: token,
            }
            checkExtensions(user);
        }
    }, [localStorage.getItem('authUser'), localStorage.getItem('token')])

    useEffect(() => {
        const handleAppExtension = (event: any) => {
            const extensionCallbackData = event?.data?.fb;
            if (extensionCallbackData) {
              const { type, success, error } = extensionCallbackData;
              console.log("success", success)
              console.log("extensionCallbackData", extensionCallbackData)
              switch (type) {
                case "INIT_RESULT":
                    const versionExtension = extensionCallbackData?.version;
                    const extensionName = extensionCallbackData?.extensionName;
                    console.log("versionExtension,", versionExtension)
                    console.log("extensionName", extensionName)

                    if ((!versionExtension || compareVersions(versionExtension, EXTENSION_VERSION) < 0) && !extensionName) { 
                        confirm({
                          title: `Vui lòng nâng cấp extension lên bản mới nhất (${EXTENSION_VERSION}) để có trải nghiệm tốt hơn?`,
                          icon: <ExclamationCircleFilled />,
                          okText: "Tải về",
                          onOk() {
                            window.open(LINK_EXTENSION, '_blank');
                          },
                        });
                        break;
                    }
                    if(extensionName === "Translate" && compareVersions(versionExtension, TRANSLATE_EXTENSION_VERSION) < 0) {
                      confirm({
                        title: `Vui lòng nâng cấp Translate extension lên bản mới nhất (${TRANSLATE_EXTENSION_VERSION}) để có trải nghiệm tốt hơn?`,
                        icon: <ExclamationCircleFilled />,
                        okText: "Tải về",
                        onOk() {
                          window.open(LINK_EXTENSION, '_blank');
                        },
                      });
                      break;
                    }
                    dispatch(onHandleStatusExtension(success))
                    dispatch(onHandleStatusExtensionTranslate(success))
                    break;
                case "REACT_MESSAGE_RESULT":
                  if (!success) {
                    try {
                      notification.error({
                        message: "Lỗi react tin nhắn",
                        description: error || '',
                        duration: 3,
                      });
                    } catch (error) {
                      
                    }
                  } else {
                    
                  }; 
                  break;
                case "SEND_MESSAGE_RESULT": 
                  if (!success) {
                    try {
                      notification.error({
                        message: "Lỗi gửi tin nhắn qua extension",
                        description: error || '',
                        duration: 3,
                      });
                      dispatch(onSendMessageByExtensionFail(extensionCallbackData));
                    } catch (error) {
                      
                    }
                  } else {
                    // Xử lý update timestamp vào message vừa send
                    dispatch(onSendMessageByExtension(extensionCallbackData));
                  }
                  break;
                case "MAKE_PROFILE_LINK_RESULT":
                  if (!success) {
                    notification.error({
                      message: "Lỗi MAKE_PROFILE_LINK_RESULT",
                      description: error || "",
                      duration: 3,
                    });
                  }
                  break;
          
                  case "MAKE_BUSINESS_LINK_RESULT":
                  if (!success) {
                    notification.error({
                      message: "Lỗi MAKE_BUSINESS_LINK_RESULT",
                      description: error || "",
                      duration: 3,
                    });
                  }
                  break;

                  case "TRANSLATE_RESULT":
                    if (!success) {
                      notification.error({
                        message: "Lỗi dịch một tin nhắn",
                        description: error || "Không thể dịch tin nhắn",
                        duration: 3,
                      });
                    } else {
                      // Xử lý dịch ở dưới ô chat input
                      dispatch(onChangeTextTranslateInput({
                        text: extensionCallbackData?.request?.text,
                        loading: false,
                        selectedChat: extensionCallbackData?.request?.selectedChat,
                        translateText: extensionCallbackData?.translateText,
                      }));
                    }
                    break;

                  case "TRANSLATE_LIST_RESULT":
                    if (!success) {
                      try {
                        notification.error({
                          message: "Lỗi dịch qua extension",
                          description: error || "Không thể dịch tin nhắn",
                          duration: 3,
                        });
                      } catch (error) {
                        
                      }
                    } else {
                      // Xử lý update timestamp vào message vừa send
                      let listMessageTranslated: { language: string; translate: string; messageId: string }[] = [];
                      extensionCallbackData?.messages?.map((item: any) => {
                        dispatch(onTranslationMessageId(item));
                        const payload = {
                          language: item?.to,
                          translate: item?.translate,
                          messageId: item?.messageId
                        }
                        listMessageTranslated.push(payload);
                      })
                      const data = {
                        messages: listMessageTranslated,
                        conversationId: extensionCallbackData?.request?.conversationId
                      }
                      const resUpdateListMess =  onUpdateListMessageTranslate(data);
                    }
                    break;
              }
            }
        };
    
        window.addEventListener("message", handleAppExtension);
        return () => window.removeEventListener("message", handleAppExtension);
      }, []);


    return <React.Fragment>
    </React.Fragment>
};

export default AppExtension;
function compareVersions(versionExtension: string, EXTENSION_VERSION: string): number {
  const [v1, v2] = [versionExtension, EXTENSION_VERSION].map(v => v?.split('.').map(Number));
  for (let i = 0; i < Math.max(v1?.length, v2?.length); i++) {
    const num1 = v1[i] || 0;
    const num2 = v2[i] || 0;
    if (num1 > num2) return 1;
    if (num1 < num2) return -1;
  }
  return 0;
}

